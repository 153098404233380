import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import Qs from 'qs';

const http = axios.create({
  // baseURL: 'https://automat.jfetek.com/ourmart/api', //! ourmart
  // baseURL: 'https://automat.jfetek.com/sandbox/api', //! sandbox
  // baseURL: '/2000001/api', //! 本地環境
  baseURL: `${process.env.VUE_APP_API_ORIGIN}${process.env.VUE_APP_API_COMMON_PATH}/api`, //! prod
  timeout: 60000,
  // headers: { 'X-Custom-Header': 'foobar' }
});
// http response 攔截器
http.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          // eslint-disable-next-line no-param-reassign
          error.message = '錯誤請求';
          break;
        case 401:
          // eslint-disable-next-line no-param-reassign
          error.message = '未授權，請重新登陸';
          break;
        case 403:
          // eslint-disable-next-line no-param-reassign
          error.message = '拒絕訪問';
          break;
        case 404:
          // eslint-disable-next-line no-param-reassign
          error.message = '請求錯誤,未找到該資源';
          break;
        case 405:
          // eslint-disable-next-line no-param-reassign
          error.message = '請求方法未允許';
          break;
        case 408:
          // eslint-disable-next-line no-param-reassign
          error.message = '請求超時';
          break;
        case 500:
          // eslint-disable-next-line no-param-reassign
          error.message = '服務端出錯';
          break;
        case 501:
          // eslint-disable-next-line no-param-reassign
          error.message = '網路未實現';
          break;
        case 502:
          // eslint-disable-next-line no-param-reassign
          error.message = '網路錯誤';
          break;
        case 503:
          // eslint-disable-next-line no-param-reassign
          error.message = '服務不可用';
          break;
        case 504:
          // eslint-disable-next-line no-param-reassign
          error.message = '網路超時';
          break;
        case 505:
          // eslint-disable-next-line no-param-reassign
          error.message = 'http版本不支持該請求';
          break;
        default:
          // eslint-disable-next-line no-param-reassign
          error.message = `連接錯誤${error.response.status}`;
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      error.message = '連接到服務器失敗';
    }
    return Promise.reject(error.message);
  },
);

class APIS {
  getRequest = (url, params) => {
    const options = {
      method: 'get',
      url,
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.AjaxFunction(options);
  }

  getRequestToken = (url, params) => {
    const options = {
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${params}`,
        'Content-Type': 'application/json',
      },
    };

    return this.AjaxFunction(options);
  }

  getRequestBlobToken = (url, params) => {
    const options = {
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${params}`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      responseType: 'blob',
    };

    return this.AjaxFunction(options);
  }

  getItem = (url, params, token) => {
    const options = {
      method: 'get',
      url,
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    return this.AjaxFunction(options);
  }

  postRequest = (url, params) => {
    const options = {
      method: 'post',
      url,
      data: params,
      headers: {
        'Content-Type': 'application/json',
        // RequestVerificationToken: headerval
      },
    };
    return this.AjaxFunction(options);
  }

  postImg = (url, params, token) => {
    const options = {
      method: 'post',
      url,
      data: params,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      processData: false,
      contentType: false,
    };
    return this.AjaxFunction(options);
  }

  postRequestForMemberOrder = (url, params, token) => {
    const options = {
      method: 'post',
      url,
      data: params,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    return this.AjaxFunction(options);
  }

  postRequestForLogin = (url, params) => {
    const options = {
      method: 'post',
      url,
      data: Qs.stringify(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // RequestVerificationToken: headerval
      },
    };
    return this.AjaxFunction(options);
  }

  AjaxFunction = (options) => new Promise((resolve, reject) => {
    http(options)
      .then((res) => {
        if (res.status === '200') {
          return resolve(res);
        }
        return reject(res);
      })
      .catch((err) => reject(err));
  })
}
export default APIS;
