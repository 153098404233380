<template>
  <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <router-link class="navbar-brand col-sm-3 col-md-2 mr-0" href="#" to="/">
    {{title}}</router-link>
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <a class="nav-link" href="#" @click.prevent="signout">登出</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      title: '智販取後台',
    };
  },
  methods: {
    signout() {
      const vm = this;
      vm.setCookie('Member', '', -1);
      vm.$router.push('/');
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${cname}=${cvalue};${expires};path=/`;
    },
  },
  // created() {
  //   const vm = this;
  //   vm.title = process.env.VUE_APP_API_COMMON_PATH.replace('/', '');
  // },
};
</script>
