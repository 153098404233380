<template>
  <nav class="col-md-2 d-md-block bg-light sidebar">
    <div class="sidebar-sticky">
      <!-- eslint-disable -->
      <h6
        class="sidebar-heading d-flex justify-content-between align-items-center px-3 my-4 text-muted"
      >
        <!-- eslint-disable -->
        <span>管理</span>
        <a class="d-flex align-items-center text-muted" href="#">
          <span class="material-icons d-md-none d-inline-block"> menu </span>
        </a>
      </h6>
      <ul id="nav-list"class="nav flex-column mb-2 nav-control">
        <li class="nav-item">
          <router-link
            class="nav-link text-main"
            to="/admin/Pickup"
            :class="{ active: pathCheck == '/admin/Pickup' }"
          >
            訂單管理
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from 'jquery';

export default {
  computed: {
    pathCheck() {
      return this.$route.path;
    },
  },
  mounted() {
    $('.material-icons').click((e) => {
      e.preventDefault();
      $('#nav-list').toggleClass('nav-control');
    });
    $('.nav-item').click((e) => {
      e.preventDefault();
      $('#nav-list').toggleClass('nav-control');
    });
  },
};
</script>

<style lang="scss">
.sidebar-heading {
  a:hover {
    text-decoration: none;
  }
}

.nav-link {
  &.active {
    font-weight: bolder;
    color: #0056b3;
  }
}

.nav.nav-control {
  @media (max-width: 480px) {
    display: none;
  }
}
</style>
