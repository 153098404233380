import http from './ajax';

const API_URL = {
  shelfListUrl: '/commodity/shelf',
  // refreshListUrl: '/machine/refresh',
};

class Api extends http {
  getShelfList = async (params = {}, option = {}) => {
    const url = `${API_URL.shelfListUrl}?machine_code=${option.machine_code}&meal=${option.meal}&date=${option.date}&status=1&brand_name=${option.brand_name}&cabinet=${option.cabinet}`;
    try {
      const response = await this.getRequestToken(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default new Api();
