<template>
  <div class="text-center d-flex">
    <!-- 讀取效果 -->
    <loading :active.sync="isLoading"></loading>
    <form class="form-signin" @submit.prevent="signin">
      <h1 class="h3 mb-3 font-weight-normal">請登入</h1>
      <label for="inputEmail" class="sr-only">帳號</label>
      <input
        type="text"
        id="inputEmail"
        class="form-control"
        placeholder="帳號"
        v-model="user.id"
        required
        autofocus
      />
      <label for="inputPassword" class="sr-only">密碼</label>
      <input
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="密碼"
        v-model="user.passwd"
        required
      />
      <button class="btn btn-lg btn-primary btn-block" type="submit">
        登入
      </button>
      <p class="mt-5 mb-3 text-muted">&copy; 2019-2021 ALL RIGHTS RESERVED. POWERED BY <a href="https://www.jfetek.com/" target="_blank" rel="noreferrer noopener">JFETEK</a></p>
    </form>
  </div>
</template>

<script>
import API from '@/api/authApi';
import Common from '@/common';
import sha512 from 'crypto-js/sha512';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      user: {
        id: '',
        passwd: '',
      },
      postData: {
        id: '',
        passwd: '',
      },
      isLoading: false,
    };
  },
  methods: {
    signin() {
      const vm = this;
      if (vm.user.id === '' || vm.user.passwd === '') {
        vm.$swal({
          heightAuto: false,
          icon: 'warning',
          text: '尚有空格未填寫！',
          confirmButtonText: '確定',
        });
      } else {
        const hash = sha512(vm.user.passwd); // 把密碼用 SHA512 加密
        vm.isLoading = true;
        vm.postData.id = vm.user.id;
        vm.postData.passwd = hash.toString();
        API.login(vm.postData).then((res) => {
          vm.isLoading = false;
          if (res.status === 200) {
            const tmpToken = res.data.data.token;
            Common.setCookie('Member', tmpToken, 0.375);
            vm.$swal({
              heightAuto: false,
              icon: 'success',
              text: '登入成功',
              confirmButtonText: '確定',
            }).then(() => {
              vm.$router.push('/admin/Pickup');
            });
          } else if (res === '未授權，請重新登陸') {
            vm.$swal({
              icon: 'warning',
              text: '帳號或密碼錯誤',
              confirmButtonText: '確定',
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
html,body {
  height: 100vh;
}

.text-center {
  height: 100%;
}

.form-signin {
  width: 100%;
  width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
