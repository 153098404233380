import http from './ajax';

const API_URL = {
  machineListUrl: '/machine',
  refreshListUrl: '/machine/refresh',
};

class Api extends http {
  getMachineList = async (params = {}) => {
    const url = `${API_URL.machineListUrl}`;
    try {
      const response = await this.getRequestToken(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }

  refreshMachineList = async (params = {}) => {
    const url = `${API_URL.refreshListUrl}`;
    try {
      const response = await this.getRequestToken(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }

  updateMachine = async (data, token) => {
    const url = `${API_URL.machineListUrl}`;
    try {
      const response = await this.postRequestForMemberOrder(url, data, token);
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default new Api();
