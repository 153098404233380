import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import Loading from 'vue-loading-overlay';
import App from './App.vue';
import router from './router';
import store from './store';

import 'vue-loading-overlay/dist/vue-loading.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

Vue.component('Loading', Loading);

Vue.config.productionTip = false;
const options = {
  heightAuto: false,
};
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2, options);
Vue.use(require('vue-moment'));

Vue.component('Loading', Loading);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
