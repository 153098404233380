import http from './ajax';

const API_URL = {
  pickupUrl: '/pickup',
  createPickupUrl: '/pickup/create',
  itemPickupUrl: '/pickup/item',
  codeUrl: '/pickup/code',
  addUrl: '/pickup/preorder',
};

class Api extends http {
  getPickupList = async (params = {}) => {
    const url = `${API_URL.pickupUrl}`;
    try {
      const response = await this.getRequestToken(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }

  postCreatePickup = async (data, token) => {
    const url = `${API_URL.createPickupUrl}`;
    try {
      const response = await this.postRequestForMemberOrder(url, data, token);
      return response;
    } catch (err) {
      return err;
    }
  }

  postItemPickup = async (data, token) => {
    const url = `${API_URL.itemPickupUrl}`;
    try {
      const response = await this.postRequestForMemberOrder(url, data, token);
      return response;
    } catch (err) {
      return err;
    }
  }

  getCode = async (params = {}, id) => {
    const url = `${API_URL.codeUrl}?pickup_id=${id}`;
    try {
      const response = await this.getRequestToken(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }

  postAddPreorder = async (data, token) => {
    const url = `${API_URL.addUrl}`;
    try {
      const response = await this.postRequestForMemberOrder(url, data, token);
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default new Api();
