import http from './ajax';

const API_URL = {
  loginUrl: '/auth/admin/login',
  getMeUrl: '/admin/me',
};

class Api extends http {
  login = async (params = {}) => {
    const url = `${API_URL.loginUrl}`;
    try {
      const response = await this.postRequestForLogin(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }

  getAuthStatus = async (token = {}) => {
    const url = `${API_URL.getMeUrl}`;
    try {
      const response = await this.getRequestToken(url, token);
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default new Api();
