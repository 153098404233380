import http from './ajax';

const API_URL = {
  stockListUrl: '/stock',
  restockUrl: '/restock',
  refreshStockUrl: '/stock/refresh',
};

class Api extends http {
  getStockList = async (params = {}) => {
    const url = `${API_URL.stockListUrl}`;
    try {
      const response = await this.getRequestToken(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }

  getRestockList = async (params = {}) => {
    const url = `${API_URL.restockUrl}?max=50`;
    try {
      const response = await this.getRequestToken(url, params);
      return response;
    } catch (err) {
      return err;
    }
  }

  updateRestockList = async (token, data = {}, option = {}, time) => {
    const url = `${API_URL.restockUrl}?machine_code=${option.machine_code}&meal=${option.meal}&restock_dt=${option.numberDate}&times=${time}`;
    try {
      const response = await this.postRequestForMemberOrder(url, data, token);
      return response;
    } catch (err) {
      return err;
    }
  }

  refreshStock = async (token) => {
    const url = `${API_URL.refreshStockUrl}`;
    try {
      const response = await this.getRequestToken(url, token);
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default new Api();
