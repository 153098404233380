import http from './ajax';

const API_URL = {
  preorderUrl: '/commodity/preorder',
  codeUrl: '/preorder',
  // refreshListUrl: '/machine/refresh',
};

class Api extends http {
  getPreorderList = async (data = {}, token, option = {}) => {
    const url = `${API_URL.preorderUrl}?machine_code=${option.machine_code}&meal=${option.meal}`;
    try {
      const response = await this.postRequestForMemberOrder(url, data, token);
      return response;
    } catch (err) {
      return err;
    }
  }

  getNewPreorderList = async (token, option = {}) => {
    const url = `${API_URL.codeUrl}?machine_code=${option.machine_code}&pickup_dt=${option.pickup_dt}`;
    try {
      const response = await this.getRequestToken(url, token);
      return response;
    } catch (err) {
      return err;
    }
  }
}

export default new Api();
