import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Admin from '@/views/Admin.vue';
import Pickup from '@/views/Pickup.vue';
import Privacy from '@/views/Privacy.vue';

Vue.use(VueRouter);

const routes = [
  { // 進入不存在的路由時回到機器列表頁
    path: '*',
    redirect: '/admin/MachineList',
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: '智販取 - 登入',
    },
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: '智販取 - 隱私權政策',
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: 'Pickup',
        name: 'Pickup',
        component: Pickup,
        meta: {
          title: '智販取 - 訂單管理',
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 動態切換標題
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
