<template>
  <div>
    <loading :active.sync="isLoading"></loading>
    <div class="describe mt-4">
      <h3>使用說明：</h3>
      <ol>
        <li>先產生取貨碼。</li>
        <li>接著按下新增訂單。</li>
        <li>輸入訂單號碼並選擇來源及機器。</li>
        <li>按下確認儲存。</li>
        <li>選擇該筆訂單的商品新增。</li>
        <li>新增完成後按下關閉完成訂單輸入。</li>
      </ol>
    </div>
    <div class="mt-4">
      <div class="row justify-content-end">
        <button class="btn btn-success mr-3" @click="openModal('preorder')">
          產生取貨碼
        </button>
        <button class="btn btn-success" @click="openModal('create')">
          新增訂單
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table mt-4 rwd-table">
        <thead>
          <tr>
            <th>時間</th>
            <th>訂單編號</th>
            <th>機器名稱</th>
            <th>機器編號</th>
            <th>來源</th>
            <th>功能</th>
          </tr>
        </thead>
        <tbody v-if="pageData && pageData.length > 0">
          <tr v-for="item in pageData" :key="item.pickup_id">
            <td data-th="時間">{{ item.create_at | moment("YYYY-MM-DD") }}</td>
            <td data-th="訂單編號">
              {{ item.pickup_id }}
            </td>
            <td data-th="機器名稱">
              {{
                machineData.filter((data) => {
                  return data.machine_code === item.machine_code;
                })[0].name
              }}
            </td>
            <td data-th="機器編號">
              {{ item.machine_code }}
            </td>
            <td data-th="來源">{{ item.source }}</td>
            <td data-th="功能">
              <button
                class="btn btn-outline-secondary btn-sm mr-1"
                @click="openModal('detail', item.preorder)"
              >
                詳細資料
              </button>
              <button
                class="btn btn-outline-secondary btn-sm mr-1"
                @click="openModal('add', item)"
              >
                新增商品
              </button>
              <!-- <button
                class="btn btn-outline-secondary btn-sm"
                @click="getCode(item)"
                :class="{ 'btn-outline-danger': item.status == 0 }"
              >
                取得Code
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-end">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a
            class="page-link"
            href="#"
            aria-label="Previous"
            @click.prevent="currentPage -= 1"
          >
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li
          class="page-item"
          v-for="item in page"
          :key="item"
          :class="{ active: currentPage === item }"
        >
          <a class="page-link" href="#" @click.prevent="currentPage = item">{{
            item
          }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === page }">
          <a
            class="page-link"
            href="#"
            aria-label="Next"
            @click.prevent="currentPage += 1"
          >
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- Modal -->

    <!-- 新增訂單用 -->
    <div
      class="modal fade"
      id="createModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="createModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-header bg-dark text-white">
            <h5 class="modal-title" id="createModalLabel">
              <span>新增訂單</span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="pickupId">請輸入 Uber Eats 或 foodpanda 的訂單編號</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="createPickupData.pickup_id"
                    id="pickupId"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="source">選擇來源</label>
                  <select
                    class="form-control"
                    v-model="createPickupData.source"
                    id="source"
                  >
                    <option value="null" disabled>請選來源</option>
                    <option value="ubereat">ubereat</option>
                    <option value="foodpanda">foodpanda</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="machineNo">選擇機器</label>
                  <select
                    class="form-control"
                    v-model="createPickupData.machine_code"
                    id="machineNo"
                  >
                    <option value="null" disabled>請選擇機器</option>
                    <option
                      :value="item.machine_code"
                      v-for="item in machineData"
                      :key="item.machine_code"
                    >
                      {{ item.name }} {{ item.machine_code }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-dismiss="modal"
            >
              取消
            </button>
            <button type="button" class="btn btn-info" @click="createPickup()">
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增商品用 -->
    <div
      class="modal fade"
      id="itemModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="itemModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-header bg-dark text-white">
            <h5 class="modal-title" id="itemModalLabel">
              <span>新增商品</span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table mt-4 rwd-table">
                <thead>
                  <tr>
                    <th>商品名稱</th>
                  </tr>
                </thead>
                <tbody
                  v-if="
                    pickupDetail.preorder && pickupDetail.preorder.length > 0
                  "
                >
                  <tr
                    v-for="(item, index) in pickupDetail.preorder"
                    :key="index"
                  >
                    <td data-th="商品名稱">
                      {{ item.commodity.commodity_name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>新增商品</p>
            <div class="row mt-3" v-if="unusedCode && unusedCode.length > 0">
              <div
                class="col-4"
                v-for="item in unusedCode"
                :key="item.preorder_code"
              >
                <div class="form-group">
                  <input
                    class="mr-2"
                    type="checkbox"
                    :name="item.commodity.commodity_name"
                    :id="item.preorder_code"
                    :value="item.preorder_code"
                    v-model="updatePickupData"
                  />
                  <label :for="item.preorder_code">{{
                    item.commodity.commodity_name
                  }}</label>
                  <!-- <select
                    class="form-control"
                    v-model="updatePickupData"
                    id="upProduct"
                  >
                    <option value="null" disabled>請選擇商品</option>
                    <option
                      :value="item.commodity_code"
                    >
                      {{ item.commodity.commodity_name }}
                    </option>
                  </select> -->
                </div>
              </div>
            </div>
            <div v-else>沒有商品，請先新增取貨碼</div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-dismiss="modal"
            >
              關閉
            </button>
            <button type="button" class="btn btn-info" @click="addItem()">
              新增
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 詳細資料用 -->
    <div
      class="modal fade"
      id="pickupDetailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="pickupModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-header bg-dark text-white">
            <h5 class="modal-title" id="pickupModalLabel">
              <span>詳細資料</span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table mt-4">
                <thead>
                  <tr>
                    <!-- <th>啟用</th> -->
                    <th>訂單編號</th>
                    <th>名稱</th>
                  </tr>
                </thead>
                <tbody v-if="pickupDetail && pickupDetail.length > 0">
                  <tr v-for="(item, key, index) in pickupDetail" :key="index">
                    <!-- <td class="pl-3">
                      <input
                        type="checkbox"
                        v-model="pickupDetail[key].status"
                        :true-value="1"
                        :false-value="0"
                        @change="updatePickup(item)"
                        :disabled="isGetCode"
                      />
                    </td> -->
                    <td>{{ item.pickup_id }}</td>
                    <td>
                      {{ item.commodity.commodity_name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-info" data-dismiss="modal">
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="createpreorderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="createpreorderModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-lg"
        role="document"
      >
        <div class="modal-content border-0">
          <div class="modal-header bg-dark text-white">
            <h5 class="modal-title" id="createpreorderModalLabel">
              <span>新增取貨碼</span>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="text-white">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="meal"> 預定餐期 </label>
                  <select
                    class="form-control mb-3 mb-md-0"
                    v-model="option.meal"
                    id="meal"
                  >
                    <option value="" disabled>請選擇時段</option>
                    <option value="lunch">午餐</option>
                    <option value="dinner">晚餐</option>
                    <option value="none">無餐期</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-froup">
                  <label for="machine">選擇機器</label>
                  <select
                    class="form-control"
                    id="machine"
                    v-model="option.machine_code"
                  >
                    <option value="" disabled>請選擇機器</option>
                    <option
                      v-for="item in machineData"
                      :value="item.machine_code"
                      :key="item.machine_code"
                    >
                      {{ item.machine_code }} ({{ item.name }})
                    </option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-12">
                <div class="form-group mt-3">
                  <label for="pickup_dt"> 預定日期 </label>
                  <date-picker
                    class="w-100"
                    v-model="option.date"
                    valueType="timestamp"
                    id="pickup_dt"
                  ></date-picker>
                </div>
              </div> -->
              <div class="col-12">
                <button class="btn btn-success" @click="getShelf">
                  取得資料
                </button>
              </div>
              <!-- <div class="col-6">
                <div class="form-froup">
                  <label for="brand">選擇商店</label>
                  <select
                    class="form-control"
                    id="brand"
                    v-model="createpreorder.brand_id"
                  >
                    <option value="" disabled>請選擇商店</option>
                    <option
                      v-for="item in shopData"
                      :value="item.brand_id"
                      :key="item.brand_id"
                    >
                      {{ item.brand_name }} ({{ item.brand_id }})
                    </option>
                  </select>
                </div>
              </div> -->
              <div class="col-12">
                <div class="form-froup mt-3">
                  <label for="">選擇商品</label>
                  <!-- <select
                    class="form-control"
                    id="commodity"
                    v-model="createpreorder.commodity_code"
                  >
                    <option value="">選擇商品</option>
                    <option
                      v-for="item in productData"
                      :value="item.commodity_code"
                      :key="item.commodity_code"
                    >
                      {{ item.commodity_name }} ({{ item.brand_name }})
                    </option>
                  </select> -->
                  <div class="table-responsive">
                    <table class="table rwd-table">
                      <thead>
                        <tr>
                          <th>選擇</th>
                          <th>名稱</th>
                          <th>數量</th>
                        </tr>
                      </thead>
                      <tbody v-if="productData && productData.length > 0">
                        <tr
                          v-for="item in productData"
                          :key="item.commodity_code"
                        >
                          <td>
                            <input
                              type="checkbox"
                              name=""
                              :id="`quantity${item.commodity_code}`"
                              :value="item"
                              v-model="selectedItem"
                            />
                          </td>
                          <td data-th="名稱">
                            <label :for="`quantity${item.commodity_code}`">{{
                              item.commodity_name
                            }}</label>
                          </td>
                          <td data-th="數量">
                            <input
                              type="number"
                              name=""
                              id=""
                              v-model.number="item.quantity"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-info"
              @click.prevent="creatPreorder"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import machineApi from '@/api/machineApi';
import stockApi from '@/api/stockApi';
import authApi from '@/api/authApi';
import pickupApi from '@/api/pickupApi';
import shelfApi from '@/api/shelfApi';
import preorderApi from '@/api/preorderApi';

export default {
  data() {
    return {
      pickupData: [],
      pickupAllData: [],
      pickupDetail: [],
      machineData: [],
      stockData: [],
      createPickupData: {
        pickup_id: '',
        source: null,
        machine_code: null,
      },
      updatePickupData: [],
      token: '',
      isLoading: false,
      page: 0,
      currentPage: 1,
      isGetCode: false,
      option: {
        machine_code: '',
        meal: '',
        date: '',
        brand_name: '',
        cabinet: '',
        pickup_dt: '',
      },
      productData: [],
      createpreorder: {
        commodity_code: '',
        pickup_dt: '',
        brand_id: '',
        machine_code: '',
        meal: '',
      },
      selectedItem: [],
      unusedCode: [],
    };
  },
  methods: {
    getPickupList() {
      const vm = this;
      let tmpData = [];
      vm.pickupData = [];
      pickupApi.getPickupList(vm.token).then((res) => {
        if (res.status === 200) {
          vm.pickupAllData = res.data.data;
          tmpData = res.data.data;
          tmpData.forEach((item, i) => {
            if (i % 10 === 0) {
              vm.pickupData.push([]);
            }
            const page = parseInt(i / 10, 10);
            vm.pickupData[page].push(item);
          });
          vm.page = vm.pickupData.length;
        } else {
          vm.$swal({
            icon: 'warning',
            text: '網路錯誤，請稍後再試',
            confirmButtonText: '確定',
          });
        }
      });
    },
    getMachineList() {
      const vm = this;
      machineApi.getMachineList(vm.token).then((res) => {
        vm.isLoading = false;
        if (res.status === 200 && res.data.ok) {
          res.data.data.forEach((element) => {
            if (element.status === 1) {
              vm.machineData.push(element);
            }
          });
        } else {
          vm.$swal({
            icon: 'warning',
            text: '網路錯誤，請稍後再試',
            confirmButtonText: '確定',
          });
        }
      });
    },
    getStockList() {
      const vm = this;
      stockApi.getStockList(vm.token).then((res) => {
        vm.isLoading = false;
        if (res.status === 200 && res.data.ok) {
          vm.stockData = res.data.data;
        } else {
          vm.$swal({
            icon: 'warning',
            text: '網路錯誤，請稍後再試',
            confirmButtonText: '確定',
          });
        }
      });
    },
    createPickup() {
      const vm = this;
      if (
        vm.createPickupData.pickup_id === ''
        || vm.createPickupData.source === null
        || vm.createPickupData.machine_code === null
        || vm.createPickupData.source === 'null'
        || vm.createPickupData.machine_code === 'null'
      ) {
        vm.$swal({
          icon: 'warning',
          text: '所有項目皆為必填，請再次確認',
          confirmButtonText: '確定',
        });
      } else {
        vm.isLoading = true;
        // vm.createPickupData.pickup_id = vm.createPickupData.pickup_id.toLowerCase();
        pickupApi
          .postCreatePickup(vm.createPickupData, vm.token)
          .then((res) => {
            vm.isLoading = false;
            if (res.status === 200) {
              vm.$swal({
                icon: 'success',
                text: '新增成功',
                confirmButtonText: '確定',
              }).then(() => {
                vm.isLoading = true;
                vm.getPickupList();
                vm.pickupDetail = res.data.data;
                vm.option.pickup_dt = vm.$moment().format('YYYYMMDD');
                vm.option.machine_code = vm.createPickupData.machine_code;
                preorderApi
                  .getNewPreorderList(vm.token, vm.option)
                  .then((resData) => {
                    vm.isLoading = false;
                    vm.unusedCode = resData.data.data;
                    // vm.option.machine_code = '';
                    // vm.option.meal = '';
                    $('#createModal').modal('hide');
                    $('#itemModal').modal({ show: true, focus: false });
                  });
              });
            } else {
              vm.$swal({
                icon: 'warning',
                text: '網路錯誤，請稍後再試',
                confirmButtonText: '確定',
              });
            }
          });
      }
    },
    addItem() {
      const vm = this;
      if (vm.updatePickupData.length < 1) {
        vm.$swal({
          icon: 'warning',
          text: '請先選擇商品',
          confirmButtonText: '確定',
        });
      } else {
        const tmpPostData = {
          preorder_code: '',
          pickup_id: '',
        };
        vm.isLoading = true;
        for (let i = 0; i < vm.updatePickupData.length; i += 1) {
          tmpPostData.preorder_code = vm.updatePickupData[i];
          tmpPostData.pickup_id = vm.pickupDetail.pickup_id;
          pickupApi.postAddPreorder(tmpPostData, vm.token).then(() => {
          });
        }
        vm.isLoading = false;
        vm.$swal({
          icon: 'success',
          text: '新增成功',
          confirmButtonText: '確定',
        }).then(() => {
          vm.getPickupList();
          $('#itemModal').modal('hide');
        });
      }
    },
    getCode(data) {
      const vm = this;
      vm.$swal({
        icon: 'info',
        text: '確定產生 Coupon Code?',
        confirmButtonText: '確定',
        showCancelButton: true,
        cancelButtonText: '取消',
        reverseButtons: true,
      }).then((swData) => {
        if (swData.value) {
          vm.isLoading = true;
          pickupApi.getCode(vm.token, data.pickup_id).then((res) => {
            vm.isLoading = false;
            if (res.status === 200) {
              vm.$swal({
                icon: 'success',
                text: '新增成功',
                confirmButtonText: '確定',
              }).then(() => {
                vm.getPickupList();
              });
            } else if (res.status === 204) {
              vm.$swal({
                icon: 'warning',
                text: '已取過 Coupon Code',
                confirmButtonText: '確定',
              });
            } else {
              vm.$swal({
                icon: 'warning',
                text: '網路錯誤，請稍後再試',
                confirmButtonText: '確定',
              });
            }
          });
        }
      });
    },
    updatePickup(data) {
      const vm = this;
      vm.isLoading = true;
      pickupApi.postItemPickup(data, vm.token).then((res) => {
        vm.isLoading = false;
        if (res.status !== 200) {
          vm.$swal({
            icon: 'warning',
            text: '網路錯誤，請稍後再試',
            confirmButtonText: '確定',
          });
        }
      });
    },
    openModal(type, data) {
      const vm = this;
      if (type === 'create') {
        $('#createModal').modal({ show: true, focus: false });
      } else if (type === 'detail') {
        vm.pickupDetail = data;
        // const tmpCoedCheck = data.filter((item) => item.coupon_code !== '');
        // if (tmpCoedCheck.length > 0) {
        //   vm.isGetCode = true;
        // } else {
        //   vm.isGetCode = false;
        // }
        $('#pickupDetailModal').modal({ show: true, focus: false });
      } else if (type === 'add') {
        vm.pickupDetail = data;
        vm.createPickupData.machine_code = vm.pickupDetail.machine_code;
        vm.isLoading = true;
        vm.option.pickup_dt = vm.$moment().format('YYYYMMDD');
        vm.option.machine_code = vm.createPickupData.machine_code;
        preorderApi.getNewPreorderList(vm.token, vm.option).then((resData) => {
          // console.log(resData);
          vm.isLoading = false;
          vm.unusedCode = resData.data.data;
          $('#createModal').modal('hide');
          $('#itemModal').modal({ show: true, focus: false });
        });
        $('#itemModal').modal({ show: true, focus: false });
      } else if (type === 'preorder') {
        $('#createpreorderModal').modal({ show: true, focus: false });
      }
    },
    getCookie(cname) {
      const name = `${cname}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    checkToken(token) {
      const vm = this;
      authApi.getAuthStatus(token).then((res) => {
        if (res.status !== 200) {
          vm.$swal({
            icon: 'warning',
            text: '請重新登入',
            confirmButtonText: '確定',
          }).then(() => {
            vm.$router.push('/');
          });
        } else {
          vm.getMachineList();
          vm.getPickupList();
          // vm.getStockList();
        }
      });
    },
    getShelf() {
      const vm = this;
      vm.isLoading = true;
      if (vm.option.machine_code === null || vm.option.meal === null) {
        vm.isLoading = false;
        vm.$swal({
          icon: 'warning',
          text: '選項不得為空',
          confirmButtonText: '確定',
        });
      } else {
        shelfApi.getShelfList(vm.token, vm.option).then((res) => {
          vm.isLoading = false;
          if (res.status === 200) {
            vm.productData = res.data.data;
          } else {
            vm.$swal({
              icon: 'warning',
              text: '網路錯誤，請稍後再試',
              confirmButtonText: '確定',
            });
          }
        });
      }
    },
    creatPreorder() {
      const vm = this;
      vm.isLoading = true;
      preorderApi
        .getPreorderList(vm.selectedItem, vm.token, vm.option)
        .then((res) => {
          vm.isLoading = false;
          if (res.status === 200) {
            vm.$swal({
              icon: 'success',
              text: '新增成功',
              confirmButtonText: '確定',
            }).then(() => {
              vm.selectedItem = [];

              $('#createpreorderModal').modal('hide');
            });
          } else {
            vm.$swal({
              icon: 'warning',
              text: '網路錯誤，請稍後再試',
              confirmButtonText: '確定',
            });
          }
        });
    },
  },
  created() {
    const vm = this;
    vm.token = vm.getCookie('Member');
    vm.checkToken(vm.token);
  },
  mounted() {},
  computed: {
    pageData() {
      const vm = this;
      return vm.pickupData[vm.currentPage - 1];
    },
    commodityData() {
      const vm = this;
      let tmpData = [];
      tmpData = vm.stockData.filter(
        (item) => item.machine_code === vm.createPickupData.machine_code,
      );
      return tmpData;
    },
    displayData() {
      const vm = this;
      let tmpData = [];
      tmpData = vm.pickupAllData.filter(
        (item) => item.pickup_id === vm.pickupDetail.pickup_id,
      );
      return tmpData;
    },
  },
};
</script>
